@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
body {
	font-family: 'Inter';
}
::-webkit-scrollbar {
	width: 2px;
	height: 4px;
}

::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 12px;
}
.bg-size-full {
	background-size: 100% 100%;
}
